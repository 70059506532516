import moment from 'moment';

export const formatDate = time => {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
}

const subtractDay = days => {
    return moment().subtract(days, 'days').format('YYYY-MM-DD');
}

export const today = () => {
    return moment().format('YYYY-MM-DD');
}

export const formatDay = time =>{
    return moment(time).format('YYYY-MM-DD');
}
export const momentDay = time =>{
    return moment(time, 'YYYY-MM-DD')
}
export const momentDays = time =>{
    return moment(time)
}
