export const routerlink = {
    methods:{
        routerChange(name, key, query = null){
            if(query){
                this.$router.push({path:key, query:{...query}})
            }else{
                this.$router.push(key)
            }
            if(this.$store.state.routelist.findIndex(el=>el.key == key) == -1){
                this.$store.commit('setRouteList',{name, key, query})
            }
        }
    },
}