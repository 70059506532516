<template>
    <div class="pd20x flex1">
        <div class="contentBox h100pct ofA">
            <div class="filter" style="padding:10px 20px">
              <!-- <div>
                <a-button type="primary" @click="openClasses">开课</a-button>
              </div> -->
              <div>
                <label>搜索：</label>
                <a-select v-model="payStatus" style="width: 120px" @change="changePaystatus">
                  <a-select-option :value="item.val" v-for="(item,index) in payoption" :key="index">
                    {{item.name}}
                  </a-select-option>
                </a-select>
                <a-select v-model="selectVal" style="width: 120px" >
                  <a-select-option :value="item.val" v-for="(item,index) in option" :key="index">
                    {{item.name}}
                  </a-select-option>
                </a-select>
                <a-input-search placeholder="请输入搜索内容" v-model="searchInput" style="width: 300px" @search="onSearch" class="mr10px"/>
              </div>
            </div>
            <div class="order-table">
                <div class="tableHeader">
                    <div style="width:250px">商品信息</div>
                    <div style="width:150px">状态</div>
                    <div style="width:120px">交易金额</div>
                    <div style="width:120px">买家/收货人</div>
                    <div style="flex:1">备注</div>
                    <div style="width:90px">操作</div>
                </div>
                <template v-for="item in data">
                   <div class="order-table-body" :key="item.id">
                       <div class="orderHeader">
                           <div>
                               <span class="mr20px">订单号：{{item.order_no}}</span>
                               <span>下单时间：{{item.create_time}}</span>
                           </div>
                           <div></div>
                       </div>
                       <div class="order-table-content">
                            <div style="width:250px;" class="vertical">
                                <div class="inline">
                                  {{item.title}}
                                </div>
                            </div>
                            <div style="width:150px">{{item.status?item.status==1?'已支付':'已发货':'待支付'}}</div>
                            <div style="width:120px">{{item.price}}</div>
                            <div style="width:140px">{{item.nickname}}<br>{{item.username}}</div>
                            <div style="flex:1">{{item.remark}}</div>
                            <div style="width:90px">
                                <span v-if="item.status ==1" class="csP" style="color:#7ebae5" @click="sendOrder(item)">备注</span>
                            </div>
                       </div>
                   </div>
                </template>
            </div>
            <a-pagination :page-size="15" :show-total="total => `共 ${total} 条`" v-model="pagination.page" :total="total" @change="changePage"/>
        </div>
        <a-modal
            title="输入备注"
            :visible="expressShow"
            :confirm-loading="confirmLoading"
            @ok="handleOk"
            @cancel="expressShow = false"
            centered
        >
            <a-input placeholder="请输入备注信息" v-model="express_no" />
        </a-modal>
    </div>
</template>
<script>
import {abrsmRemark} from "@/libs/examapi";

const columns = [

];
import { formatDay } from '@/libs/moment'
import * as http from '@/libs/examapi'
import { routerlink } from '@/mixins/routerlink'
export default {
    name: 'purchase',
    mixins: [routerlink],
    data(){
        return{
            options: [],
            columns,
            searchInput: '',
            selectVal: 'mobile',
            payStatus: 1,
            payoption: [
              { name: '全部状态', val: -1},
              { name: '已付款', val: 1},
              { name: '待支付', val: 0}
            ],
            option: [
              { name: '电话', val: 'mobile'},
              { name: '昵称', val: 'nickname'}
            ],
            filter:{
              payStatus: 1
            },
            pagination:{
                page: 1,
                size: '15',
                pageSize: 15
            },
            total:0,
            allSubject:[],
            data: [],
            areaval: '',
            //填写快递
            expressShow: false,
            confirmLoading: false,
            express_no:null,
            currentItem: {}
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        formatDay,
        getData(){
            http.getAbrsmOrderList({...this.pagination, ...this.filter})
            .then(res => {
                let { data, total } = res
                this.total = total
                this.data = data
            }).catch(error=>{
                console.log(error)
            })
        },
        changePaystatus(e){
          this.filter.payStatus = e
          this.pagination.page = 1
          this.getData()
        },
        deleteItem(id){
            let _this = this
            this.$confirm({
                title: '是否确定删除该条信息?',
                centered: true,
                onOk() {
                    http.del_crouse_bag({id}).then(res=>{
                        let {page, pagesize, total} = _this.pagination
                        if(page>1 && Number(page-1)* 15 == total - 1){
                            _this.pagination.page = Number(page-1)
                        }
                        _this.getData()
                    }).catch(error=>{
                        console.log(error)
                    })
                },
            });
        },
        onSearch(){
          this.filter = {}
          this.filter[this.selectVal] = this.searchInput
          this.getData()
        },
        changePage(current){
            this.pagination.page = current
            this.getData()
        },
        sendOrder(data){
            this.express_no = null
            this.currentItem = data
            this.expressShow = true
        },
        handleOk(){
            if(this.express_no){
                let {id} = this.currentItem
                this.confirmLoading = true
                http.abrsmRemark({express_no:this.express_no, order_id:id}).then(res =>{
                    this.getData()
                    this.confirmLoading = false
                    this.expressShow = false
                })
            }else{
                this.$message.error('请填写备注信息')
                this.confirmLoading = false
            }
        },
        copyAddress(item, e){
            e.stopPropagation()
            let address = `${item.real_name}  ${item.phone}  ${item.province}${item.city}${item.detail}`
            this.$copyText(address).then(res=> {
                this.$message.success('复制成功')
            },error=>{
                this.$message.success('复制失败')
            })
        },
    }
}
</script>
<style lang="less" scoped>
.imgWrap{
    width: 120px;
    height: 70px;
    overflow: hidden;
    border-radius: 4px;
    img{
        width: 100%;
    }
}
.tableHeader{
    width: 100%;
    background: #eeeeee;
    display: flex;
    padding: 10px 0;
}
.orderHeader{
    background: #eeeeee;
    display: flex;
    padding: 10px 20px;
}
.order-table{
    min-width: 900px;
    padding: 10px;
    .order-table-body{
        margin-top: 10px;
    }
    .order-table-content{
        border: 1px solid #e6e8f0;
        border-top: none;
        border-right: none;
        display: flex;
        >div{
            min-width: 20px;
            border-right: 1px solid #e6e8f0;
            padding: 5px 10px;
            display: flex;
            align-items: center;
        }
        .vertical{
            flex-direction: column;
            justify-content: center;
            padding: 0;
            >.inline{
                display: block;
                width: 100%;
                border-bottom: 1px solid #e6e8f0;
                padding: 5px 10px;
                &:last-child{
                    border-bottom: 0;
                }
            }
        }
    }

}
.copy{
    color: royalblue;
    cursor: pointer;
}

</style>
