import service from '@/libs/request'

const BaseUrl = 'https://theory.lanqingnet.com/'

export function send_user_goods(data){
  return service({
    url: `${BaseUrl}/admin/Subject/sendUserGoods`,
    method: 'post',
    data
  })
}
export function get_orders_list(data){
  return service({
    url: `${BaseUrl}/admin/Subject/getOrdersList`,
    method: 'post',
    data
  })
}
export function get_shop_goods(data){
  return service({
    url: `${BaseUrl}/admin/Subject/getShopGoods`,
    method: 'post',
    data
  })
}
export function del_shop_goods(data){
  return service({
    url: `${BaseUrl}/admin/Subject/delShopGoods`,
    method: 'post',
    data
  })
}
export function add_shop_goods(data){
  return service({
    url: `${BaseUrl}/admin/Subject/addShopGoods`,
    method: 'post',
    data
  })
}
export function edit_shop_goods(data){
  return service({
    url: `${BaseUrl}/admin/Subject/editShopGoods`,
    method: 'post',
    data
  })
}

export function get_cate(data) {
    return service({
      url: `${BaseUrl}/admin/SystemSet/getCate`,
      method: 'post',
      data
    })
}
export function get_cate_s(data) {
    return service({
      url: `${BaseUrl}/admin/SystemSet/getCateS`,
      method: 'post',
      data
    })
}
export function del_cate_s(data) {
  return service({
    url: `${BaseUrl}/admin/SystemSet/delCate`,
    method: 'post',
    data
  })
}
export function add_cate_s(data) {
  return service({
    url: `${BaseUrl}/admin/SystemSet/addCate`,
    method: 'post',
    data
  })
}

//账号列表
export function get_users(data) {
  return service({
    url: `${BaseUrl}/admin/SystemSet/getUsers`,
    method: 'post',
    data
  })
}
export function add_user(data) {
  return service({
    url: `${BaseUrl}/admin/SystemSet/addUser`,
    method: 'post',
    data
  })
}
export function edit_user(data) {
  return service({
    url: `${BaseUrl}/admin/SystemSet/editUser`,
    method: 'post',
    data
  })
}
export function del_user(data) {
  return service({
    url: `${BaseUrl}/admin/SystemSet/delUser`,
    method: 'post',
    data
  })
}
export function edit_password(data) {
  return service({
    url: `${BaseUrl}/admin/SystemSet/editPass`,
    method: 'post',
    data
  })
}

//展示营销
export function get_banner(){
  return service({
    url: `${BaseUrl}/admin/SystemSet/getBanner`,
    method: 'post',
  })
}
export function add_banner(data){
  return service({
    url: `${BaseUrl}/admin/SystemSet/addBanner`,
    method: 'post',
    data
  })
}
export function edit_banner(data){
  return service({
    url: `${BaseUrl}/admin/SystemSet/editBanner`,
    method: 'post',
    data
  })
}
export function del_banner(data){
  return service({
    url: `${BaseUrl}/admin/SystemSet/delBanner`,
    method: 'post',
    data
  })
}
export function order_banner(data){
  return service({
    url: `${BaseUrl}/admin/SystemSet/orderBanner`,
    method: 'post',
    data
  })
}
//编辑科目
export function get_subject(data){
  return service({
    url: `${BaseUrl}/admin/Subject/getSubject`,
    method: 'post',
    data
  })
}
export function add_subject(data){
  return service({
    url: `${BaseUrl}/admin/Subject/addSubject`,
    method: 'post',
    data
  })
}
export function edit_subject(data){
  return service({
    url: `${BaseUrl}/admin/Subject/editSubject`,
    method: 'post',
    data
  })
}
export function del_subject(data){
  return service({
    url: `${BaseUrl}/admin/Subject/delSubject`,
    method: 'post',
    data
  })
}
//科目课程列表
export function get_course(data){
  return service({
    url: `${BaseUrl}/admin/Subject/getCourse`,
    method: 'post',
    data
  })
}
export function add_course(data){
  return service({
    url: `${BaseUrl}/admin/Subject/addCourse`,
    method: 'post',
    data
  })
}
export function edit_course(data){
  return service({
    url: `${BaseUrl}/admin/Subject/editCourse`,
    method: 'post',
    data
  })
}
export function del_course(data){
  return service({
    url: `${BaseUrl}/admin/Subject/delCourse`,
    method: 'post',
    data
  })
}
export function order_course(data){
  return service({
    url: `${BaseUrl}/admin/Subject/orderCourse`,
    method: 'post',
    data
  })
}

export function get_problems(data){
  return service({
    url: `${BaseUrl}/admin/Subject/getProblems`,
    method: 'post',
    data
  })
}
export function add_problems(data){
  return service({
    url: `${BaseUrl}/admin/Subject/addProblems`,
    method: 'post',
    data
  })
}
export function edit_problems(data){
  return service({
    url: `${BaseUrl}/admin/Subject/editProblems`,
    method: 'post',
    data
  })
}
export function del_problems(data){
  return service({
    url: `${BaseUrl}/admin/Subject/delProblems`,
    method: 'post',
    data
  })
}
export function order_Problems(data){
  return service({
    url: `${BaseUrl}/admin/Subject/orderProblems`,
    method: 'post',
    data
  })
}

//课程表管理
export function get_subject_cate(){
  return service({
    url: `${BaseUrl}/admin/Subject/getCates`,
    method: 'post',
  })
}
export function get_allSubject(){
  return service({
    url: `${BaseUrl}/admin/Subject/getSubject`,
    method: 'post',
  })
}
export function add_crouse_bag(data){
  return service({
    url: `${BaseUrl}/admin/Subject/addCrouseBag`,
    method: 'post',
    data
  })
}

export function del_crouse_bag(data){
  return service({
    url: `${BaseUrl}/admin/Subject/delCrouseBag`,
    method: 'post',
    data
  })
}
export function edit_crouse_bag(data){
  return service({
    url: `${BaseUrl}/admin/Subject/editBagSubject`,
    method: 'post',
    data
  })
}
export function get_crouse_list(data){
  return service({
    url: `${BaseUrl}/admin/Subject/getCrouseBag`,
    method: 'post',
    data
  })
}
export function get_class_detail(data){
  return service({
    url:`${BaseUrl}/admin/Subject/getBagSubject`,
    method: 'post',
    data
  })
}
export function get_subject_detail(data){
  return service({
    url:`${BaseUrl}/admin/Subject/detailBagSubject`,
    method: 'post',
    data
  })
}

//上传通用讲义
export function editSet(data){
  return service({
    url:`${BaseUrl}/admin/SystemSet/editSet`,
    method: 'post',
    data
  })
}

//huoqu 通用讲义
export function getSet(){
  return service({
    url:`${BaseUrl}/admin/SystemSet/getSet`,
    method: 'post',
  })
}

// 添加专业
export function getProfessional(){
  return service({
    url:`${BaseUrl}/admin/SystemSet/getPro`,
    method: 'post',
  })
}

//huoqu 通用讲义
export function addProfessional(data){
  return service({
    url:`${BaseUrl}/admin/SystemSet/addPro`,
    method: 'post',
    data
  })
}

//huoqu 通用讲义
export function delProfessional(data){
  return service({
    url:`${BaseUrl}/admin/SystemSet/delPro`,
    method: 'post',
    data
  })
}

//获取学校
export function getSchool(){
  return service({
    url:`${BaseUrl}/admin/SystemSet/getSchool`,
    method: 'post',
  })
}

//添加学校
export function addSchool(data){
  return service({
    url:`${BaseUrl}/admin/SystemSet/addSchool`,
    method: 'post',
    data
  })
}

//删除学校
export function delSchool(data){
  return service({
    url:`${BaseUrl}/admin/SystemSet/delSchool`,
    method: 'post',
    data
  })
}

//添加大分类
export function addBPid(data){
  return service({
    url:`${BaseUrl}/admin/SystemSet/addBpid`,
    method: 'post',
    data
  })
}

//获取大分类
export function getBPid(data){
  return service({
    url:`${BaseUrl}/admin/SystemSet/getBpid`,
    method: 'post',
    data
  })
}

//添加大分类
export function delBpid(data){
  return service({
    url:`${BaseUrl}/admin/SystemSet/delBpid`,
    method: 'post',
    data
  })
}
export function getGoodsCate(data){
  return service({
    url:`${BaseUrl}/admin/User/getGoodsCate`,
    method: 'get',
  })
}
export function addGoodsCate(data){
  return service({
    url:`${BaseUrl}/admin/User/addGoodsCate`,
    method: 'post',
    data
  })
}
export function editGoodsCate(data){
  return service({
    url:`${BaseUrl}/admin/User/editGoodsCate`,
    method: 'post',
    data
  })
}
export function delGoodsCate(data){
  return service({
    url:`${BaseUrl}/admin/User/delGoodsCate`,
    method: 'post',
    data
  })
}
export function addBasicSet(data){
  return service({
    url:`${BaseUrl}/admin/User/basic`,
    method: 'post',
    data
  })
}
export function getBasicSet(data){
  return service({
    url:`${BaseUrl}/admin/User/basicDetail`,
    method: 'post',
    data
  })
}
export function getGoodTask(data){
  return service({
    url:`${BaseUrl}/admin/User/getGoodTask`,
    method: 'post',
    data
  })
}
export function getUserPapers(data){
  return service({
    url:`${BaseUrl}/admin/User/getUserPapers`,
    method: 'post',
    data
  })
}
export function addPaperNums(data){
  return service({
    url:`${BaseUrl}/admin/User/addPaperNums`,
    method: 'post',
    data
  })
}
export function sendPapers(data){
  return service({
    url:`${BaseUrl}/admin/User/sendPapers`,
    method: 'post',
    data
  })
}

export function addQuestionSet(data){
  return service({
    url:`${BaseUrl}/admin/User/questionBank`,
    method: 'post',
    data
  })
}
export function getQuestionSet(data){
  return service({
    url:`${BaseUrl}/admin/User/questionDetail`,
    method: 'post',
    data
  })
}
//保存为详情模板
export function addDetailModel(data){
  return service({
    url:`${BaseUrl}/admin/User/addTemplate`,
    method: 'post',
    data
  })
}
export function getDetailModel(){
  return service({
    url:`${BaseUrl}/admin/User/templateList`,
    method: 'post',
  })
}
export function delDetailModel(data){
  return service({
    url:`${BaseUrl}/admin/User/delTemplate`,
    method: 'post',
    data
  })
}
//查询科目下试卷
export function getExam(data){
  return service({
    url:`${BaseUrl}/admin/User/goodTaskList`,
    method: 'post',
    data
  })
}
export function addExam(data){
  return service({
    url:`${BaseUrl}/admin/User/addGoodTask`,
    method: 'post',
    data
  })
}
export function editExam(data){
  return service({
    url:`${BaseUrl}/admin/User/editGoodTask`,
    method: 'post',
    data
  })
}
export function delExam(data){
  return service({
    url:`${BaseUrl}/admin/User/delGoodTask`,
    method: 'post',
    data
  })
}
export function orderExam(data){
  return service({
    url:`${BaseUrl}/admin/User/orderGoodTask`,
    method: 'post',
    data
  })
}
//获取商品分类下的科目
export function getGList(data){
  return service({
    url:`${BaseUrl}/admin/User/gtList`,
    method: 'post',
    data
  })
}
//添加商品分类下的科目
export function addGList(data){
  return service({
    url:`${BaseUrl}/admin/User/addGt`,
    method: 'post',
    data
  })
}
//删除商品分类下的科目
export function delGList(data){
  return service({
    url:`${BaseUrl}/admin/User/delGt`,
    method: 'post',
    data
  })
}

export function addStudyHelp(data){
  return service({
    url:`${BaseUrl}/admin/User/addStudyHelp`,
    method: 'post',
    data
  })
}

export function editStudyHelp(data){
  return service({
    url:`${BaseUrl}/admin/User/editStudyHelp`,
    method: 'post',
    data
  })
}
export function delStudyHelp(data){
  return service({
    url:`${BaseUrl}/admin/User/delStudyHelp`,
    method: 'post',
    data
  })
}

//学员管理
export function setUserAgent(data){
  return service({
    url:`${BaseUrl}/admin/User/setUserAgent`,
    method: 'post',
    data
  })
}
//学员管理
export function getUserList(data){
  return service({
    url:`${BaseUrl}/admin/User/wechatUserList`,
    method: 'post',
    data
  })
}
//学员绑定科目
export function bindUserCate(data){
  return service({
    url:`${BaseUrl}/admin/User/userBindCate`,
    method: 'post',
    data
  })
}
//学员绑定科目列表
export function getUserCateList(data){
  return service({
    url:`${BaseUrl}/admin/User/userBindList`,
    method: 'post',
    data
  })
}
//订单管理
export function getOrderList(data) {
  return service({
    url:`${BaseUrl}/admin/cart/orderList`,
    method: 'post',
    data
  })
}
//发货
export function sendOrder(data) {
  return service({
    url:`${BaseUrl}/admin/cart/send`,
    method: 'post',
    data
  })
}

export function getAbrsmOrderList(data) {
  return service({
    url:`${BaseUrl}/admin/cart/abrsmOrderList`,
    method: 'post',
    data
  })
}
export function abrsmRemark(data) {
  return service({
    url:`${BaseUrl}/admin/cart/abrsmRemark`,
    method: 'post',
    data
  })
}
export function userPapersList(data) {
  return service({
    url:`${BaseUrl}/admin/cart/userPapersList`,
    method: 'post',
    data
  })
}
